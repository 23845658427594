import { PlanBillingCyclesPricingScheme } from '@felicetteapp/types';
import React from 'react';
import { Input } from '../../../components/Input';
import { NewPlanFormPriceInput } from './PriceInput';
type NewPlanFormPricingSchemeProps = {
  value: PlanBillingCyclesPricingScheme;
  onChange: (newVal: PlanBillingCyclesPricingScheme) => void;
};
const NewPlanFormPricingSchemeBase: React.FC<NewPlanFormPricingSchemeProps> = ({
  value,
  onChange,
}) => {
  return (
    <div className="p-2 mb-2 border border-main-500 hover:border-main-300">
      <header className="mb-2">Pricing</header>
      <div className="flex items-center">
        <div className="whitespace-nowrap">version</div>
        <Input
          type="number"
          value={value.version}
          placeholder="Version"
          title="Version"
          className="w-full mb-2 ml-2"
          onChange={(e) => {
            const newValue = e.target.value;
            onChange({ ...value, version: Number(newValue) });
          }}
        />
      </div>
      <div className="mt-2">
        <NewPlanFormPriceInput
          value={value.fixed_price}
          onChange={(e) => onChange({ ...value, fixed_price: e })}
        />
      </div>
    </div>
  );
};
export const NewPlanFormPricingScheme = React.memo(
  NewPlanFormPricingSchemeBase,
);
