import {
  PlanBillingCyclesFrequency,
  PlanBillingCyclesFrequencyInterval,
} from '@felicetteapp/types';
import React from 'react';
import { Input } from '../../../components/Input';
type NewPlanFormFrequencyInputProps = {
  value: PlanBillingCyclesFrequency;
  onChange: (newVal: PlanBillingCyclesFrequency) => void;
};
const NewPlanFormFrequencyInputBase: React.FC<NewPlanFormFrequencyInputProps> = ({
  value,
  onChange,
}) => {
  return (
    <div className="p-2 mb-2 border border-main-500 hover:border-main-300 grid grid-cols-3 items-center gap-x-2">
      <div>frequency</div>
      <Input
        placeholder="interval"
        value={value.interval_count}
        onChange={(e) => {
          const newValue = e.target.value;
          onChange({ ...value, interval_count: Number(newValue) });
        }}
      />
      <select
        placeholder="Tenure type"
        className="border-main-500 hover:border-main-300 focus:border-main-100 border-solid border bg-transparent outline-none px-2 py-1 placeholder-main-400 w-full"
        value={value.interval_unit}
        onChange={(e) => {
          const newValue = e.target.value as PlanBillingCyclesFrequencyInterval;
          onChange({
            ...value,
            interval_unit: newValue,
          });
        }}
      >
        {Object.keys(PlanBillingCyclesFrequencyInterval).map((TenureType) => (
          <option value={PlanBillingCyclesFrequencyInterval[TenureType]}>
            {TenureType}
          </option>
        ))}
      </select>
    </div>
  );
};

export const NewPlanFormFrequencyInput = React.memo(
  NewPlanFormFrequencyInputBase,
);
