import {
  Plan,
  PlanBillingCyclesFrequencyInterval,
  PlanBillingCyclesTenureType,
  PlanStatus,
  Product,
} from '@felicetteapp/types';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import { Spinner } from '../../../components/Spinner';
import { useApi } from '../../../hooks/useApi';
import { SelectProducts } from '../../Products/SelectProducts';
import { NewPlanFormBillingCycles } from './BillingCycles';

const NewPlanFormBase = () => {
  const { send, sending, error, response } = useApi<{
    error: boolean;
    plan: string;
  }>('admin/plans', { method: 'post' }, false);
  const [currentPlan, setCurrentPlan] = useState<Omit<Plan, 'id'>>({
    name: '',
    status: PlanStatus.ACTIVE,
    description: '',
    billing_cycles: [
      {
        pricing_scheme: {
          version: 1,
          fixed_price: { currency_code: 'USD', value: '1.00' },
          create_time: new Date().toISOString(),
          update_time: new Date().toISOString(),
        },
        frequency: {
          interval_count: 1,
          interval_unit: PlanBillingCyclesFrequencyInterval.MONTH,
        },
        tenure_type: PlanBillingCyclesTenureType.TRIAL,
        sequence: 2,
        total_cycles: 1,
      },
    ],
    product_id: '',
    quantity_supported: true,
    payment_preferences: {
      auto_bill_outstanding: true,
    },
  });
  const [currentProduct, setCurrentProduct] = useState<Product>(null);
  useEffect(() => {
    if (currentProduct) {
      setCurrentPlan((currentPlan) => ({
        ...currentPlan,
        product_id: currentProduct.id,
      }));
    }
  }, [currentProduct, setCurrentPlan]);

  const handleSubmit = useCallback(() => {
    console.log(currentPlan);
    send({ data: currentPlan });
  }, [currentPlan, send]);
  return (
    <div className="p-2 border border-gray-800">
      <header className="m-1">
        <h2 className="text-xl">New plan</h2>
      </header>
      <main>
        <SelectProducts
          placeholder="Product"
          value={currentProduct}
          onChange={setCurrentProduct}
          className="mb-2"
        />
        <Input
          placeholder="Name"
          className="w-full"
          value={currentPlan.name}
          onChange={(e) => {
            const newValue = e.target.value;
            setCurrentPlan((currentPlan) => ({
              ...currentPlan,
              name: newValue,
            }));
          }}
        />
        <textarea
          placeholder="Description"
          className="w-full border border-main-500 hover:border-main-300 focus:border-main-100 bg-transparent p-2 mt-2 outline-none placeholder-main-400"
          value={currentPlan.description}
          onChange={(e) => {
            const newValue = e.target.value;
            setCurrentPlan((currentPlan) => ({
              ...currentPlan,
              description: newValue,
            }));
          }}
        />
        <NewPlanFormBillingCycles
          value={currentPlan.billing_cycles}
          onChange={(e) =>
            setCurrentPlan({ ...currentPlan, billing_cycles: e })
          }
        />
        {error ? <div className="bg-red-900 p-2 my-2">Ops!</div> : null}
        {response ? (
          <div className="bg-green-900 p-2 my-2">
            plan {response.data.plan} created!
          </div>
        ) : null}
        <Button
          className="block w-full p-2 bg-green-800 hover:bg-green-700 cursor-pointer"
          onClick={handleSubmit}
        >
          {sending ? <Spinner /> : 'Create'}
        </Button>
      </main>
    </div>
  );
};
export const NewPlanForm = React.memo(NewPlanFormBase);
