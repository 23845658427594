import { Plan, PlanBillingCycles } from '@felicetteapp/types';
import React, { useState } from 'react';
import * as Paypal from '../../../../../pseudoPaypalTypes';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { Spinner } from '../../../../components/Spinner';
import { useApi } from '../../../../hooks/useApi';

const ModalContent: React.FC<{ data: Paypal.Plan }> = ({ data }) => {
  const {
    id,
    name,
    description,
    product_id,
    status,
    billing_cycles,
    payment_preferences,
    quantity_supported,
    taxes,
  } = data;
  console.log({ taxes });
  return (
    <div>
      <section className="py-2">
        <p>
          <strong>id:</strong> {id}
        </p>
        <p>
          <strong>name:</strong> {name}
        </p>
        <p>
          <strong>description:</strong> {description}
        </p>
        <p>
          <strong>product_id:</strong> {product_id}
        </p>
        <p>
          <strong>status:</strong> {status}
        </p>
        <p>
          <strong>quantity_supported:</strong>{' '}
          {quantity_supported ? 'TRUE' : 'FALSE'}
        </p>
      </section>
      <h2 className="my-2 text-xl">Payment preferences</h2>
      <section className="py-2">
        <p>
          <strong>auto_bill_outstanding:</strong>{' '}
          {payment_preferences.auto_bill_outstanding ? 'TRUE' : 'FALSE'}
        </p>
        <p>
          <strong>payment_failure_threshold:</strong>{' '}
          {payment_preferences.payment_failure_threshold
            ? payment_preferences.payment_failure_threshold
            : null}
        </p>
        <p>
          <strong>setup_fee_failure_action:</strong>{' '}
          {payment_preferences.setup_fee_failure_action
            ? payment_preferences.setup_fee_failure_action
            : null}
        </p>
      </section>
      <h2 className="my-2 text-xl">Billing cycles</h2>
      {billing_cycles.map((bc) => (
        <ItemBillingCycle data={bc} />
      ))}
    </div>
  );
};
const ItemDetailsModalBase: React.FC<{ id: string; onClose: () => void }> = ({
  id,
  onClose,
}) => {
  const { fetching, response, error } = useApi<Paypal.Plan>(
    `paypal/billing/plans/${id}`,
    { method: 'get' },
  );
  return (
    <Modal header={<h1>Plan {id}</h1>} onClose={onClose}>
      {fetching ? (
        <Spinner />
      ) : error ? (
        <div className="bg-red-900 p-2 text-center">ops!</div>
      ) : response ? (
        <ModalContent data={response.data} />
      ) : null}
    </Modal>
  );
};
const ItemDetailsModal = React.memo(ItemDetailsModalBase);

const ItemBillingCycleBase: React.FC<{ data: PlanBillingCycles }> = ({
  data,
}) => {
  const {
    frequency,
    pricing_scheme,
    tenure_type,
    total_cycles,
    sequence,
  } = data;
  return (
    <div className="border border-gray-800 p-2">
      <p>
        <strong>Type: </strong>
        {tenure_type}
      </p>
      <p>
        <strong>Frequency: </strong>
        {`${frequency.interval_count} ${frequency.interval_unit}`}
      </p>
      <p>
        <strong>Total cycles: </strong>
        {total_cycles}
      </p>
      <p>
        <strong>Price: </strong>
        {`${pricing_scheme.fixed_price.value} ${pricing_scheme.fixed_price.currency_code}`}
      </p>
      <p>
        <strong>Sequence: </strong>
        {sequence}
      </p>
    </div>
  );
};

const ItemBillingCycle = React.memo(ItemBillingCycleBase);
type ListPlansItemProps = {
  plan: Plan | Paypal.Plan;
};
const ListPlansItemBase: React.FC<ListPlansItemProps> = ({ plan }) => {
  const { id, name, description, product_id, status } = plan;
  console.log({ product_id });
  console.log({ plan });
  const [showingDetails, setShowingDetails] = useState(false);

  return (
    <>
      <div className="grid grid-cols-4 gap-x-2 p-2">
        <div>
          <strong>{name}</strong>
          <p>{description}</p>
          <strong className="truncate">id:</strong>
          <p className="truncate">{id}</p>
          <strong>Product:</strong>
          <p className="truncate">{product_id}</p>
          <p>
            <strong>Quantity supported: </strong>
            {'quantity_supported' in plan
              ? plan.quantity_supported
                ? 'TRUE'
                : 'FALSE'
              : null}
          </p>
          <p>
            <strong>Status: </strong>
            {status}
          </p>
        </div>
        <div className="col-span-2">
          {'billing_cycles' in plan
            ? plan.billing_cycles.map((thisBillingCycle) => (
                <ItemBillingCycle data={thisBillingCycle} />
              ))
            : null}
        </div>
        <div>
          <Button onClick={() => setShowingDetails(true)}>show details</Button>
        </div>
      </div>
      {showingDetails ? (
        <ItemDetailsModal
          id={plan.id}
          onClose={() => setShowingDetails(false)}
        />
      ) : null}
    </>
  );
};
export const ListPlansItem = React.memo(ListPlansItemBase);
