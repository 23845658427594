import { Product } from '@felicetteapp/types';
import React from 'react';
import { Spinner } from '../../../components/Spinner';
import { useApi } from '../../../hooks/useApi';
import { AiOutlineCheckCircle } from 'react-icons/ai';
type SelectProductsProps = {
  value: Product;
  placeholder: string;
  className?: string;
  onChange: (selectedProduct: Product) => void;
};
const SelectProductsBase: React.FC<SelectProductsProps> = ({
  onChange,
  placeholder,
  value,
  className = '',
}) => {
  const { response, fetching, error } = useApi<{ products: Array<Product> }>(
    'admin/products',
    {
      method: 'get',
    },
  );
  return (
    <div
      className={`flex border border-main-500 hover:border-main-300 p-2 ${className}`}
    >
      <div>{placeholder}</div>
      <div className="flex-1 pl-2">
        {fetching ? (
          <Spinner />
        ) : error ? (
          <div className="bg-red-900 p-2">ops!</div>
        ) : response ? (
          response.data.products.map((product) => (
            <div
              className={`p-1 cursor-pointer flex items-center ${
                value && value.id === product.id
                  ? 'bg-gray-500 hover:bg-gray-600 text-gray-900'
                  : 'bg-gray-900 hover:bg-gray-800'
              }`}
              onClick={() => onChange(product)}
            >
              {product.name}
              {value && value.id === product.id ? (
                <AiOutlineCheckCircle className="ml-2" />
              ) : null}
            </div>
          ))
        ) : null}
      </div>
    </div>
  );
};
export const SelectProducts = React.memo(SelectProductsBase);
