import { Plan } from '@felicetteapp/types';
import React from 'react';
import * as Paypal from '../../../../pseudoPaypalTypes';
import { ListPlansItem } from './Item';
type ListPlansProps = {
  plans: Array<Plan>;
  paypalPlans: Array<Paypal.Plan>;
};
const ListPlansBase: React.FC<ListPlansProps> = ({ plans, paypalPlans }) => {
  console.log({ paypalPlans });

  const plansWithoutPaypal = plans.filter(
    (plan) =>
      paypalPlans.map((paypalPlan) => paypalPlan.name).indexOf(plan.name) < 0 &&
      paypalPlans
        .map((paypalPlan) => paypalPlan.description)
        .indexOf(plan.description) < 0,
  );
  const plansWithPaypal = paypalPlans.filter(
    (paypalPlan) =>
      plans.map((plan) => plan.name).indexOf(paypalPlan.name) > -1 &&
      plans.map((plan) => plan.description).indexOf(paypalPlan.description) >
        -1,
  );
  const plansOnlyOnPaypal = paypalPlans.filter(
    (paypalPlan) =>
      plans.map((plan) => plan.name).indexOf(paypalPlan.name) < 0 &&
      plans.map((plan) => plan.description).indexOf(paypalPlan.description) < 0,
  );
  return (
    <div>
      <h2 className="text-xl p-2">Plans with paypal</h2>
      {plansWithPaypal.map((plan) => (
        <ListPlansItem plan={plan} />
      ))}
      <h2 className="text-xl p-2 border-t border-gray-800">
        Plans without paypal
      </h2>
      {plansWithoutPaypal.map((plan) => (
        <ListPlansItem plan={plan} />
      ))}
      <h2 className="text-xl p-2 border-t border-gray-800">
        Plans that only exists on paypal
      </h2>
      {plansOnlyOnPaypal.map((plan) => (
        <ListPlansItem plan={plan} />
      ))}
    </div>
  );
};
export const ListPlans = React.memo(ListPlansBase);
