import React from 'react';
import { withLayout } from '../../containers/Layout';
import { Plan } from '@felicetteapp/types';
import { useApi } from '../../hooks/useApi';
import { Spinner } from '../../components/Spinner';
import { NewPlanForm } from '../../containers/Plans/NewPlanForm';
import { ListPlans } from '../../containers/Plans/ListPlans';
import * as Paypal from '../../../pseudoPaypalTypes';

const PagePlansBase = () => {
  const { fetching, response, error } = useApi<{ plans: Array<Plan> }>(
    'admin/plans',
    { method: 'get' },
  );
  const {
    fetching: paypalFetching,
    response: paypalResponse,
    error: paypalError,
  } = useApi<{ plans: Array<Paypal.Plan>; links: Array<Paypal.Link> }>(
    'paypal/billing/plans',
    { method: 'get' },
  );
  console.log({ paypalFetching, paypalResponse, paypalError });
  return (
    <>
      <div className="py-2 border border-gray-800 mb-2">
        {fetching || paypalFetching ? (
          <Spinner />
        ) : error || paypalError ? (
          <div className="bg-red-900 px-2 py-2">ops!</div>
        ) : (
          <ListPlans
            plans={response.data.plans}
            paypalPlans={paypalResponse.data.plans}
          />
        )}
      </div>
      <NewPlanForm />
    </>
  );
};
const PagePlans = React.memo(PagePlansBase);
export default () => withLayout({ title: 'Plans' }, <PagePlans />);
