import {
  PlanBillingCycles,
  PlanBillingCyclesFrequencyInterval,
  PlanBillingCyclesTenureType,
} from '@felicetteapp/types';
import React from 'react';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import { NewPlanFormFrequencyInput } from './FrequencyInput';
import { NewPlanFormPricingScheme } from './PricingScheme';
type NewPlanFormBillingCyclesPropsItem = {
  value: PlanBillingCycles;
  onChange: (newVal: PlanBillingCycles) => void;
};
const NewPlanFormBillingCyclesItemBase: React.FC<NewPlanFormBillingCyclesPropsItem> = ({
  value,
  onChange,
}) => {
  return (
    <div className="p-2 mb-2 border border-main-500 hover:border-main-300">
      <header className="pb-2">
        <h4>Billing cycle</h4>
      </header>
      <select
        placeholder="Tenure type"
        className="border-main-500 hover:border-main-300 focus:border-main-100 border-solid border bg-transparent outline-none px-2 py-1 placeholder-main-400 w-full mb-2"
        value={value.tenure_type}
        onChange={(e) => {
          const newValue = e.target.value;
          onChange({
            ...value,
            tenure_type: newValue as PlanBillingCyclesTenureType,
          });
        }}
      >
        {Object.keys(PlanBillingCyclesTenureType).map((TenureType) => (
          <option value={PlanBillingCyclesTenureType[TenureType]}>
            {TenureType}
          </option>
        ))}
      </select>
      <NewPlanFormFrequencyInput
        value={value.frequency}
        onChange={(e) => onChange({ ...value, frequency: e })}
      />
      <NewPlanFormPricingScheme
        value={value.pricing_scheme}
        onChange={(e) => onChange({ ...value, pricing_scheme: e })}
      />
      <div className="flex items-center ">
        <div className="pr-2 whitespace-no-wrap">Total cycles</div>
        <Input
          type="number"
          value={value.total_cycles}
          placeholder="Total cycles"
          title="Total cycles"
          className="w-full mb-2"
          onChange={(e) => {
            const newValue = e.target.value;
            onChange({ ...value, total_cycles: Number(newValue) });
          }}
        />
      </div>
      <div className="flex items-center ">
        <div className="pr-2 whitespace-nowrap">Sequence</div>
        <Input
          type="number"
          value={value.sequence}
          placeholder="Sequence"
          title="Sequence"
          className="w-full mb-2"
          onChange={(e) => {
            const newValue = e.target.value;
            onChange({ ...value, sequence: Number(newValue) });
          }}
        />
      </div>
    </div>
  );
};

export const NewPlanFormBillingCyclesItem = React.memo(
  NewPlanFormBillingCyclesItemBase,
);

type NewPlanFormBillingCyclesProps = {
  value: Array<PlanBillingCycles>;
  onChange: (newVal: Array<PlanBillingCycles>) => void;
};

const NewPlanFormBillingCyclesBase: React.FC<NewPlanFormBillingCyclesProps> = ({
  value,
  onChange,
}) => {
  return (
    <div className="p-2 mb-2 border border-main-500 hover:border-main-300 flex">
      <header className="p-2">
        <h3>Billing cycles</h3>
      </header>
      <section className="ml-2">
        {value.map((thisVal, i) => (
          <div className="flex items-start">
            <div>
              <NewPlanFormBillingCyclesItem
                value={thisVal}
                onChange={(e) => {
                  const newValues = [...value];
                  newValues[i] = e;
                  onChange(newValues);
                }}
              />
            </div>
            <Button
              onClick={() => {
                const confirmRemoval = window.confirm('remove billing cycle?');
                if (confirmRemoval) {
                  const newValues = [...value];
                  newValues.splice(i, 1);
                  onChange(newValues);
                }
              }}
              className="flex-1 ml-2 block w-full bg-red-700 hover:bg-red-800 cursor-pointer"
              title="remove billing cycle"
            >
              x
            </Button>
          </div>
        ))}
        <Button
          onClick={() => {
            onChange([
              ...value,
              {
                pricing_scheme: {
                  version: 1,
                  fixed_price: { currency_code: 'USD', value: '1.00' },
                  create_time: new Date().toISOString(),
                  update_time: new Date().toISOString(),
                },
                frequency: {
                  interval_count: 1,
                  interval_unit: PlanBillingCyclesFrequencyInterval.MONTH,
                },
                tenure_type: PlanBillingCyclesTenureType.TRIAL,
                sequence: 2,
                total_cycles: 1,
              },
            ]);
          }}
          className="block w-full bg-green-700 hover:bg-green-800 cursor-pointer"
        >
          add billing cycle
        </Button>
      </section>
    </div>
  );
};

export const NewPlanFormBillingCycles = React.memo(
  NewPlanFormBillingCyclesBase,
);
