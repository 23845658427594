import { PlanBillingCyclesPricingPrice } from '@felicetteapp/types';
import React from 'react';
import { Input } from '../../../components/Input';
type PriceInputProps = {
  value: PlanBillingCyclesPricingPrice;
  onChange: (newVal: PlanBillingCyclesPricingPrice) => void;
};

const currenciesCodes = [
  ['Australian dollar', 'AUD'],
  ['Brazilian real', 'BRL'],
  ['Canadian dollar', 'CAD'],
  ['Chinese Renmenbi', 'CNY'],
  ['Czech koruna', 'CZK'],
  ['Danish krone', 'DKK'],
  ['Euro', 'EUR'],
  ['Hong Kong dollar', 'HKD'],
  ['Hungarian forint', 'HUF'],
  ['Indian rupee', 'INR'],
  ['Israeli new shekel', 'ILS'],
  ['Japanese yen', 'JPY'],
  ['Malaysian ringgit', 'MYR'],
  ['Mexican peso', 'MXN'],
  ['New Taiwan dollar', 'TWD'],
  ['New Zealand dollar', 'NZD'],
  ['Norwegian krone', 'NOK'],
  ['Philippine peso', 'PHP'],
  ['Polish złoty', 'PLN'],
  ['Pound sterling', 'GBP'],
  ['Russian ruble', 'RUB'],
  ['Singapore dollar', 'SGD'],
  ['Swedish krona', 'SEK'],
  ['Swiss franc', 'CHF'],
  ['Thai baht', 'THB'],
  ['United States dollar', 'USD'],
];
const PriceInputBase: React.FC<PriceInputProps> = ({ value, onChange }) => {
  return (
    <div className="flex items-center">
      <div className="whitespace-nowrap mr-2">Price:</div>
      <Input
        type="text"
        value={value.value}
        placeholder="Value"
        title="Value"
        className="w-full"
        onChange={(e) => {
          const newValue = e.target.value;
          onChange({ ...value, value: newValue });
        }}
      />
      <select
        placeholder="Tenure type"
        className="border-main-500 hover:border-main-300 focus:border-main-100 border-solid border bg-transparent outline-none px-2 py-1 placeholder-main-400 w-full ml-2"
        value={value.currency_code}
        onChange={(e) => {
          const newValue = e.target.value;
          onChange({
            ...value,
            currency_code: newValue,
          });
        }}
      >
        {currenciesCodes
          .sort(([nameA], [nameB]) => (nameA > nameB ? 1 : -1))
          .map(([name, code]) => (
            <option value={code}>{name}</option>
          ))}
      </select>
    </div>
  );
};
export const NewPlanFormPriceInput = React.memo(PriceInputBase);
